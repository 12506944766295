.row {
    margin-left: 30px;
    color: #fff;
}

.posters {
    display: flex;
    padding: 20px;
    overflow-x: scroll;
    overflow-y: hidden;

}

.posters::-webkit-scrollbar {
    display: none;
}

.poster {
    cursor: pointer;
    max-height: 150px;
    margin-right: 10px;
}

.smallPoster {
    cursor: pointer;
    max-height: 120px;
    margin-right: 10px
}

.poster:hover {
    transition: transform 450ms ease-in-out;
    transform: scale(1.2);
}

.smallPoster:hover {
    transition: transform 450ms ease-in-out;
    transform: scale(1.1);
}