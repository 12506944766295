.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    height: 30px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    background-color: #111;
}

.logo {
    position: fixed;
    margin-top: 5px;
    left: 20px;
    width: 90px
}

.avatar {
    position: fixed;
    right: 20px;
    width: 30px;
}